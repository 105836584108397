//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['product'],
    data() {
        return {

        }
    },
    methods: {
        detail(id) {
            this.$router.push({ path:'/product', query:{id}})
        }
    }
}
